import React from "react";
import "./../SubscriptionPlans/SubscriptionPlans.scss";
import { Collapse, Layout } from "antd";
import { Helmet } from "react-helmet";
import TextConstants from "../../TextConstants";
import MetaTagContents from "../../MetaTagContents";
import NavBar from "../landingPage/header/navbar";
import Footer from "../landingPage/footer";

const { Panel } = Collapse;
const { Content } = Layout;
const { name, content, schemaMarkup } = MetaTagContents.FAQS;

function FAQs() {
  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{MetaTagContents.FAQS.title}</title>
        <link rel="canonical" href="https://all3d.ai/faqs" />
        <meta name={name} content={content} />
        <script type="application/ld+json">
            {JSON.stringify(schemaMarkup)}
        </script>
      </Helmet>

      <NavBar />

      <Layout>
        <WebVersion />
        <MobileVersion />
      </Layout>
      <Footer />
    </React.Fragment>
  );
}

const WebVersion = () => {
  return (
    <Content className="d-none d-xl-block">
      <div>
        <div style={{ marginTop: "200px" }}>
          <div
            className="font graphik-regular"
            style={{ margin: "40px 140px" }}
          >
            <div style={{ marginTop: "30px", marginBottom: "30px" }} id="faqs">
              <div
                className="justify-in-center"
                style={{ marginBottom: "10px" }}
              >
                <h1 className="font lora w-400 f-32 ws-6 black-2b">FAQs</h1>
              </div>
              <Collapse accordion ghost={true} expandIconPosition={"right"}>
                {TextConstants.FAQS.map((faq, index) => (
                  <Panel header={faq.Q} key={index}>
                    <div>{faq.A}</div>
                  </Panel>
                ))}
              </Collapse>
            </div>
          </div>
        </div>
      </div>
    </Content>
  );
};

const MobileVersion = () => {
  return (
    <Content className="d-xl-none">
      <div>
        <div style={{ marginTop: "100px" }}>
          <div style={{ padding: 15 }}>
            <div className="justify-in-center" style={{ marginBottom: "10px" }}>
              <span className="font lora ws-2 f-20 black-2b">FAQs</span>
            </div>
            <Collapse accordion ghost={true} expandIconPosition={"right"}>
              {TextConstants.FAQS.map((faq, index) => (
                <Panel header={faq.Q} key={index}>
                  <div>{faq.A}</div>
                </Panel>
              ))}
            </Collapse>
          </div>
        </div>
      </div>
    </Content>
  );
};

export default FAQs;
