import React from "react";
import NavBar from "../landingPage/header/navbar";
import Footer from "../landingPage/footer";
import "./privacyTerms.scss";
import { withRouter, Route } from "react-router-dom";
import { Tabs, Layout, Row, Col } from "antd";
import Terms from "./terms";
import Privacy from "./privacy";
import CookieConsentBanner from "../cookieConsentBanner";
import TextConstants from "../../TextConstants";
import MetaTagContents from "../../MetaTagContents";
import { Helmet } from "react-helmet";

const { TabPane } = Tabs;
const { Content } = Layout;

const { terms, privacy } = TextConstants.PRIVACY_AND_TERMS;

class PrivacyAndTerms extends React.Component {
  constructor(props) {
    super(props);
    var url = this.props.location.pathname.split("/")[1];

    this.state = {
      tabKey: url,
    };
    // {console.log(this.props)}
  }

  onTabChanged = (key) => {
    this.setState({ tabKey: key });
    this.props.history.replace({
      pathname: "/" + key,
      state: {
        tabKey: key,
      },
    });
    // console.log(this.props)
  };

  render() {
    const PageContent = () => (
      <Tabs
        onChange={this.onTabChanged}
        activeKey={this.state.tabKey === "terms" ? "terms" : "privacy"}
      >
        <TabPane
          tab={<span className="font graphik-regular f-18">{terms}</span>}
          key="terms"
        >
          <Route exact path="/terms" component={Terms} />
        </TabPane>
        <TabPane
          tab={<span className="font graphik-regular f-18">{privacy}</span>}
          key="privacy"
        >
          <Route exact path="/privacy" component={Privacy} />
        </TabPane>
      </Tabs>
    );
    const { name, content } = MetaTagContents.PRIVACY_AND_TERMS;
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{MetaTagContents.PRIVACY_AND_TERMS.title}</title>
          <link rel="canonical" href="https://all3d.ai/terms" />
          <meta name={name} content={content} />
        </Helmet>
        <React.Fragment>
          <NavBar />
          <Layout>
            <Content style={{ marginTop: "200px" }}>
              <Row style={{ justifyContent: "flex-start" }}>
                <Col className="column-padding">
                  <PageContent />
                  <CookieConsentBanner />
                </Col>
              </Row>
            </Content>
          </Layout>
          <div className="abs-btm">
            <Footer />
          </div>
        </React.Fragment>
      </div>
    );
  }
}

export default withRouter(PrivacyAndTerms);
