import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import NavBar from "../landingPage/header/navbar";
import DottedLoader from "../DottedLoader/DottedLoader.jsx";
import { BLOGS_CONTROLLER } from "../../env";
import "./index.scss";
import Footer from "../landingPage/footer";
const BlogsPage = () => {
  const { id } = useParams();
  const [content, setContent] = useState("");
  const [meta, setMeta] = useState({ title: "", description: "" });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);


  useEffect(() => {
    // Fetch blog content and metadata from the backend
    const fetchBlogContent = async () => {
      try {
        const response = await axios.post(BLOGS_CONTROLLER, {
          doc_id: id,
          action: "get_blog_by_id",
        });
        const { html_content, meta } = response.data.body;

        setContent(html_content);
        setMeta({
          title: meta.title,
          description: meta.description,
          metaTags: (meta.meta_tags?.length ? meta.meta_tags.join(",") : "")
        });
      } catch (err) {
        setError("Failed to load the blog content.");
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchBlogContent(); // Only fetch if ID is available
    } else {
      setError("Invalid blog ID.");
      setLoading(false);
    }
  }, [id]); // Re-run effect if ID changes

  useEffect(() => {
    const interval = setInterval(() => {
      const host = document.querySelector(".blog-container"); // Corrected selection

      // Add blog content in shadow dom so that the css styles are not overwritten
      if (host && !host.shadowRoot) {        
        const shadow = host.attachShadow({ mode: "open" });
        const div = document.createElement("div");
        div.innerHTML = content;
        shadow.appendChild(div);

        clearInterval(interval); // Stop checking once attached
      }
    }, 100); // Check every 100ms until available

    return () => clearInterval(interval);
  }, [content]);

  if (loading) {
    return (
      <div className="loader">
        <NavBar />
        <DottedLoader />
      </div>
    );
  }

  if (error) {
    return (
      <div className="error">
        <NavBar />
        <p>{error}</p>
      </div>
    );
  }

  return (
    <div>
      <Helmet>
        <title>{meta.title}</title>
        <meta name="description" content={meta.description} />
        <meta name="keywords" content={meta.metaTags}/>
      </Helmet>
      <NavBar />
      <div className="blog-container"></div>
      <Footer />
    </div>
  );
};

export default BlogsPage;
