import axios from "axios";
import React, { useEffect, useState } from "react";
import { Row, Col, Card, Spin, Modal } from "antd";
import { HeartFilled, HeartOutlined } from "@ant-design/icons";
import { AI_ASSET_GENERATOR_HELPER } from "../../env";


const THUMBNAIL_BASE_URL =
  "https://d48f7equ64qjl.cloudfront.net/product_assets/thumbnail/";

const MODEL_BASE_URL =
  "https://d48f7equ64qjl.cloudfront.net/product_assets/ai_generated_glb/";

const ThreeDModelsFeed = ({favoriteAiAssets, setFavoriteAiAssets}) => {
  const [models, setModels] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedModel, setSelectedModel] = useState(null);

  useEffect(() => {
    const fetchModels = async () => {
      try {
        const api_endpoint = "https://gqlip0wnzg.execute-api.us-west-2.amazonaws.com/production/ai-asset-generator-helper";
        const payload = { action: "get_ai_3d_models_feed" };
        const res = await axios.post(api_endpoint, payload);

        if (res.data) {
          const updatedData = res.data.map((item) => ({
            ...item,
            thumbnail_url: THUMBNAIL_BASE_URL + item.thumbnail,
            model_url: MODEL_BASE_URL + item.id + '.glb', // Assuming API returns model filename
          }));

          setModels(updatedData);
        }
      } catch (error) {
        console.error("Error fetching models:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchModels();
  }, []);

  const openModal = (model) => {
    setSelectedModel(model);
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
    setSelectedModel(null);
  };

  const handleFavoriteAiModel = (model) => {
    const isFavorite = favoriteAiAssets['model_ids'].includes(model.id)
    isFavorite 
        ? setFavoriteAiAssets({
            ...favoriteAiAssets, 
            'model_ids': favoriteAiAssets['model_ids'].filter(id => id !== model.id)
          }) 
        : setFavoriteAiAssets({
            ...favoriteAiAssets, 
            'model_ids': [...favoriteAiAssets['model_ids'], model.id]
          });
    let payload = {
      action: isFavorite ? "remove_favorite_ai_asset" : "mark_ai_asset_favorite",
      product_id: model.id,
    }
    axios.post(AI_ASSET_GENERATOR_HELPER, payload)
  }

  return (
    <div style={{ padding: "20px", maxWidth: "1200px", margin: "auto", overflowY: "auto" }}>
      {loading ? (
        <div style={{ textAlign: "center", padding: "20px" }}>
          <Spin size="large" />
        </div>
      ) : (
        <Row gutter={[16, 16]} justify="center">
          {models.map((model) => (
            <Col key={model.id} xs={24} sm={12} md={8} lg={6}>
                <Card
                hoverable
                onClick={() => openModal(model)} // Open modal on click
                style={{ width: "100%", height: 250, textAlign: "center", position: "relative" }}
                cover={
                    <div
                    style={{
                        height: 160,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        overflow: "hidden",
                        background: "#f5f5f5",
                        position: "relative",
                    }}
                    >
                    <img
                        alt={model.name}
                        src={model.thumbnail_url}
                        style={{ maxWidth: "100%", maxHeight: "100%", objectFit: "contain" }}
                    />
                    {/* Heart icon in the top-right corner */}
                    <div
                        style={{
                        position: "absolute",
                        top: 10,
                        left: 10,
                        cursor: "pointer",
                        fontSize: "20px",
                        color: "black",
                        }}
                        onClick={(e) => {
                        console.log(model);
                        e.stopPropagation(); // Prevents modal opening when clicking the heart
                        handleFavoriteAiModel(model)
                        }}
                    >
                        {favoriteAiAssets['model_ids'].includes(model.id) ? 
                          <div className="img-fav-icon">
                              <HeartFilled />
                          </div> : 
                          <div className="img-fav-icon">
                              <HeartOutlined />
                          </div>
                        }
                    </div>
                    </div>
                }
                >
                <Card.Meta title={model.name} />
                </Card>
            </Col>
            ))}
        </Row>
      )}

      {/* Ant Design Modal for 3D Model Viewer */}
      <Modal
        title={selectedModel ? selectedModel.name : "3D Model Preview"}
        visible={modalVisible}
        onCancel={closeModal}
        destroyOnClose
        footer={null}
        width={800} // Adjust width if needed
        centered
      >
        {selectedModel && (
          <div style={{ textAlign: "center" }}>
            <model-viewer
                src={selectedModel.model_url}
                alt="3D View"
                auto-rotate
                camera-controls
                style={{ width: "100%", height: "500px" }}
                loading="eager"
            />
          </div>
        )}
      </Modal>
    </div>
  );
};

export default ThreeDModelsFeed;
