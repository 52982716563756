import React from "react";
const TextConstants = {
  HEADER: {
    title:
      "Create virtual products and storefronts for ecommerce and social media",
    btn_text: "Get Started For Free",
    access_msg: "Allow access to motion sensor controls?",
  },

  COOKIE_CONSENT: {
    text: "ALL3D.ai uses cookies to improve your site experience. To learn more, please read our",
    link: "Privacy Policy",
  },

  FEATURED_IN: {
    title: "FEATURED IN",
  },

  HOLISTIC_SOLUTION: {
    title: "ALL3D - A Holistic Solution",
    title_section_1: "The Holistic Solution",
    list_section_1: [
      "Create virtual products in 3D",
      "Visualize products in immersive virtual storefronts",
      "Infinitely customize virtual products and storefronts",
    ],
    title_section_2: "Infinitely Customizable Products",
    text_section_2: "Customizable products made completely virtual",
    btn_text: "See Example",
    title_section_3: "Infinitely Customizable Showrooms",
    text_section_3:
      "Showrooms and templates include living rooms, kitchens, stores, showrooms, restaurants, offices, conference rooms, hotels, houses, playgrounds, outdoor spaces and more.",
    title_section_4: "ALL3D - A Vertically Integrated Virtual-first Solution",
    text_section_4: "(click on the image to see enlarged preview)",
    title_section_5: "ALL3D Personalization Engine For Retailers",
    list_section_5: [
      "Retailers can serve consumers personalized visualizations based on consumer data in real time",
      "A retailer’s library of virtual products and ALL3D store of virtual products and spaces can be leveraged to create real-time personalized visualizations, of products and environments",
    ],
  },

  OUR_PRODUCT_OFFERING: {
    title: "Our Product Offering",
  },

  CREATE_VIRTUAL_PRODUCTS: {
    title: "Design & Create Virtual Products",
    list: [
      "Design a virtual product in 3D - without ever creating a physical sample",
      "Create a virtual 3D model of your existing product",
      "Upload your existing 3D product models",
    ],
    icon_text1: "Click on the",
    icon_text2: "icon above to view in your Room",
  },

  GENERATE_PRODUCT_IMAGERY: {
    title: "Generate Product Imagery",
    subtitle: "Create silo photography and 360s",
    text: "Use our browser-based silo software to virtually generate product imagery with a few clicks.",
  },

  CREATE_PRODUCT_VARIANTS: {
    title: "Create Product Variants",
    text: "Infinitely customize your virtual products by changing the color, size and component parts.",
  },

  CREATE_SPACES: {
    title: "Create Spaces Or Showrooms",
    text: "Design virtual experiences and showrooms in hours that would be cost-prohibitive to create in the physical world.",
  },

  ALL3D_STORE: {
    title: "ALL3D Store",
    text: "Buy and sell virtual products and spaces to design your lifestyles and showrooms.",
  },

  CREATE_BRAND_PAGE: {
    title: "Create Your Brand Page",
    text: "Sell your items on the ALL3D Store by creating your own brand page.",
  },

  CREATE_LIFESTYLE_IMAGERY: {
    title: "Create Lifestyle Images Or Room Scenes",
    text: "Design 3D virtual experiences that can be rendered as 2D photographs from any angle with results that surpass actual photography.",
  },

  REALTIME_CONFIGURATOR: {
    title: "Realtime Configurator",
    text: "Virtual products can be infinitely customized by end-consumers by changing attributes like color, materials and component parts. These are made-to-order products.",
  },

  CREATE_3D_TOURS: {
    title: "Create 3D Tours",
  },

  GENERATE_SOCIAL_MEDIA_CONTENT: {
    title: "Generate Social Media Content",
    text: "Use ALL3D library of products and spaces to create content for all your social media channels.",
  },

  AI_CONTENT: {
    title: "Our proprietary AI process creates a high-quality 3D model from a single image.",
    text_1: "Our AI model generates multiple views of your product from a single image, then uses neural surface reconstruction to build a 3D model.",
    text_2: "With a 3D model, you can quickly generate an unlimited amount of photorealistic images including product silos, lifestyle images, 360 spins, and even embed AR on your PDP.",
  },

  SCANNING_APP: {
    title: "3D Scan Your Products & Spaces",
    text: "Use the latest depth sensing LIDAR technology on your iPhone to enable scanning of physical products and spaces.",
    error_msg: "Your browser does not support HTML5 video.",
  },

  HOW_IT_WORKS: {
    title: "How Our Platform Works",
    btn_text: "Click Here To Learn More",
    error_msg: "Your browser does not support HTML5 video.",
  },

  WHO_CAN_USE_US: {
    title: "Who Can Use Us",
    text: "ALL3D enables retailers, manufacturers, interior designers and product developers to bring their products and spaces to life.",
    btn_text: "Explore",
  },

  WHY_CHOOSE_US: {
    title: "Why Choose Us",
    title_part_1: "Why",
    title_part_2: "Choose Us",
  },

  CLIENT_TESTIMONIALS: {
    title: "What Our Customers Are Saying",
    btn_text: "Read More",
  },

  TRUSTED_BY_BRANDS: {
    title: "Trusted By Top Brands",
  },

  FOOTER: {
    title: "Get Started with your Free Trial",
    text: "Create an account and try out ALL3D for free",
    btn1_text: "Get Started with ALL3D",
    btn2_text: "Sign up As Artist",
    company_name: "ALL3D, ",
    reserved_rights: ". All Rights Reserved",
    investment_consent: "Want to be an investor in ALL3D?",
    consent_btn: "Click Here",
    subtitle: ["Support", "Company"],
    support: ["How ALL3D Works", "Contact Us", "FAQs", "Return Policy"],
    company: ["About Us", "Privacy", "Terms"],
  },

  LANDING_PAGE_MODAL: {
    title: " Want to be an investor in ALL3D?",
    text: "We are raising capital on Republic NOW.",
    btn_text: "Click here to invest",
  },

  CONTACT_US_FORM: {
    title: "How can we help you?",
    text: "Please select a message type and send us a message. We will get back to you within 24 hours.",
    link_text: "Sign In to your Account",
    options: [
      "Access To Our Services",
      "Questions About Pricing And Volume Discounts",
      "Questions About Terms Of Use And Privacy Policy",
      "Other Queries Or Concerns",
    ],
  },

  STORE: {
    title: "STORE",
    items: ["Products", "Spaces", "Templates", "Brands"],
    products: "Add products to your library to use in your own designs",
    spaces:
      "Add rooms to your library to place your own products in and generate images",
    scenes:
      "Add designed rooms as templates to your library, add your own products and generate new images",
    link_text: "Visit Website",
    results: "Showing results for",
  },

  SUBSCRIPTION_PLANS: {
    title: "Pricing That Suits Everyone",
    unit_price_link_text: "Click here to view unit pricing for Enterprise",
    text_1:
      "* In-app purchases apply only to limited quantities. For quantities greater than three, please",
    contact_us_link_text: "contact us",
    text_2:
      "for enterprise subscription plans. For example, you can only create three product models per month via in-app purchases etc.",
    plans: ["Free", "Pro", "Premium", "Enterprise"],
  },

  UNIT_PRICE: {
    title: "Unit Pricing Details for Enterprise",
  },

  PRIVACY_AND_TERMS: {
    terms: "Terms of Use",
    privacy: "Privacy Policy",
  },

  ABOUT_US: {
    heading_1: "Our Mission",
    text_1:
      "To revolutionize the home goods and improvement industry where products are virtually designed, created, customized, showcased and sold, even before any physical product is manufactured or shipped. Enabling manufacturers and retailers to shift from physical-first to a virtual-first, sustainable solution.",
    text_2:
      "Giving consumers the ability to customize and personalize home products, reducing returns, waste of materials, lowering carbon footprint and a happier planet.",
    heading_2: "Our Awesome Leadership",
    heading_3: "Our Experienced Team",
    headquarters: "Headquarters: San Francisco, CA",
    work_across: "Teams working across the globe",
  },

  COMING_SOON: {
    title: "Coming Soon",
  },

  HOW_ALL3D_WORKS: {
    title: "How To Use ALL3D",
    tabs: [" Step-by-Step Guide", "Video Tutorials"],
    heading_1: "Learn what you can do",
    text_1:
      "Get started with key concepts of all the things that you can do using ALL3D platform",
    video_tutorials: [
                    {
                      id: "Aab47RP56k8",
                      title: "Creating Lifestyle Imagery using ALL3D Scene Creator",
                      link: "https://www.youtube.com/embed/Aab47RP56k8"
                    },
                    {
                      id: "Red63KyFhxo",
                      title: "How to Take Wider Shots Outside a Room using Scene Clipping in the Scene Creator",
                      link: "https://www.youtube.com/embed/Red63KyFhxo"
                    },
                    {
                      id: "6GAtWRdhnsk",
                      title: "How to Stack an Item on Top of Another Item in the Scene Creator",
                      link: "https://www.youtube.com/embed/6GAtWRdhnsk"
                    },
                    {
                      id: "YkC4p3TyACg",
                      title: "How to Snap Items Together or to a Wall in the Scene Creator",
                      link: "https://www.youtube.com/embed/YkC4p3TyACg"
                    },
                    {
                      id: "P1877Cy87Vs",
                      title: "How to Change Materials and Paints for Walls, Floors and Ceiling in the Scene Creator",
                      link: "https://www.youtube.com/embed/P1877Cy87Vs"
                    },
                    {
                      id: "Zp7czf-pcU0",
                      title: "How to Create Collections in ALL3D",
                      link: "https://www.youtube.com/embed/Zp7czf-pcU0"
                    },
                    {
                      id: "9sKJpXHFkzE",
                      title: "How to Use ALL3D Templates to Create Lifestyle Images",
                      link: "https://www.youtube.com/embed/9sKJpXHFkzE"
                    },
                    {
                      id: "BVa8M4M4syg",
                      title: "How to QA your Models",
                      link: "https://www.youtube.com/embed/BVa8M4M4syg"
                    },
                    {
                      id: "7jM_r4Qcdoc",
                      title: "How to take Silo Images using ALL3D",
                      link: "https://www.youtube.com/embed/7jM_r4Qcdoc"
                    },
                    {
                      id: "ZCisUTZ2fII",
                      title: "How to organize items in your ALL3D Libraries",
                      link: "https://www.youtube.com/embed/ZCisUTZ2fII"
                    },
                    {
                      id: "kSar-BxJoWA",
                      title: "How to request Printer Friendly Images from ALL3D",
                      link: "https://www.youtube.com/embed/kSar-BxJoWA"
                    },
                   
                  ]
  },

  NAVBAR: {
    items: ["AI Creator", "Pricing", "Blog", "Schedule a Demo", "About", "Login", "Sign up"],
  },

  TESTIMONIALS: [
    {
      bg: "https://d48f7equ64qjl.cloudfront.net/static_files/testimonials/Background.jpg",
      name: "Cortney & Bob Novogratz",
      headshot:
        "https://d48f7equ64qjl.cloudfront.net/static_files/testimonials/CortneyandBobNovogratz.png",
      position: "Designers, The Novogratz",
      full_text:
        "ALL3D upped our virtualization game for products that were still in development. With ALL3D we were able to immediately start marketing products before they even arrived from the factory.",
      text: "ALL3D upped our virtualization game for products that were still in development. With ALL3D... ",
      stars: 4,
    },
    {
      bg: "https://d48f7equ64qjl.cloudfront.net/static_files/testimonials/Background-6.jpg",
      name: "Hanna O'Meara",
      headshot:
        "https://d48f7equ64qjl.cloudfront.net/static_files/testimonials/Hanna_OMeara.png",
      position: "VP Marketing, Butler Specialty",
      full_text:
        "We are in our second year of working with ALL3D, and we are incredibly proud of the imagery that we are producing together. Our goal going into this partnership was to elevate our brand by creating photography that would visually represent who we are and honor and highlight the quality and artisanship of our products in realistic and accurate representations. Safe to say, ALL3D has delivered! Their easy-to-use portal and dedicated team of designers and modelers have created an intuitive and easy to use platform. All3D has allowed us to scale our imagery in a way that would not be financially nor physically possible with traditional lifestyle photography.",
      text: "ALL3D has allowed us to scale our imagery in a way that would not be financially nor physically possible with... ",
      stars: 4,
    },
    //   {
    //     'bg': 'https://d48f7equ64qjl.cloudfront.net/static_files/testimonials/Background-2.jpeg',
    //     'name': 'John Albanese',
    //     'headshot': 'https://d48f7equ64qjl.cloudfront.net/static_files/testimonials/JohnAlbanese.png',
    //     'position': 'CMO, Hooker Furnishings',
    //     'full_text': 'We’re very excited to be working with a high-tech company like ALL3D to revolutionize how we market and display our products… ALL3D provides us with the best way to create high-quality lifestyle imagery quickly and easily.',
    //     'text': 'We’re very excited to be working with a high-tech company like ALL3D to revolutionize... ',
    //     'stars': 4.5
    //   },
    {
      bg: "https://d48f7equ64qjl.cloudfront.net/static_files/testimonials/Background-5.jpeg",
      name: "Laura Scott",
      headshot:
        "https://d48f7equ64qjl.cloudfront.net/static_files/testimonials/LauraScott.jpg",
      position: "Director of Ecommerce, Uttermost",

      full_text: (
        <span>
          ALL3D has provided Uttermost with a cost effective and efficient
          solution for displaying our products in lifestyle settings that we
          wouldn't otherwise be able to achieve. We've been so impressed with
          their ability to quickly turnaround accurate product models and create
          custom room scenes that further enhance our products. Getting started
          was quick and easy and we've enjoyed working with the entire ALL3D
          team. <br />
          <br />
          Since working with ALL3D, we've been focused on displaying our
          barstools, counter stools, and pendant lighting in kitchen lifestyle
          scenes. Before, when browsing our selection of bar stools and counter
          stools on our website, many of them were displayed on a blank,
          uninspiring background. Now, with the help of ALL3D, we're able to
          show customers a cohesive look of how we might suggest using these
          products in a kitchen. We feel that adding these lifestyle images to
          this selection of products on our website has helped ease the purchase
          decisions for our customers.
        </span>
      ),

      text: "ALL3D has provided Uttermost with a cost effective and efficient solution for displaying our products... ",
      stars: 4.5,
    },
    {
      bg: "https://d48f7equ64qjl.cloudfront.net/static_files/testimonials/Background-3.jpg",
      name: "Meganne Wecker",
      headshot:
        "https://d48f7equ64qjl.cloudfront.net/static_files/testimonials/MeganneWecker.png",
      position: "President, Skyline Furniture",
      full_text:
        "Our partnership with ALL3D takes our visualization technology to the next level. At a time when COVID has forced us to rethink our market strategies, ALL3D allows us to pivot seamlessly and imagine new ways of showcasing our collection.",
      text: "Our partnership with ALL3D takes our visualization technology to the next level. At a time when COVID...",
      stars: 4,
    },
    {
      bg: "https://d48f7equ64qjl.cloudfront.net/static_files/testimonials/Background-4.jpg",
      name: "Tina Nicole",
      headshot:
        "https://d48f7equ64qjl.cloudfront.net/static_files/testimonials/TinaNicole.png",
      position: "Creative Director, Nathan Anthony",
      full_text:
        "ALL3D has saved me tons of hours on making creative content on my own. As a valued partner in creating digital content, ALL3D has helped me take Nathan Anthony's product models and lifestyle imagery to another level. I have been able to also create NFT's and to diversify my company's social media content. Their platform is easy to learn and intuitive. With ALL3D, I am in control and all my virtual content in one place and only limited by my imagination.",
      text: "ALL3D has saved me tons of hours on making creative content on my own. As a valued... ",
      stars: 5,
    },
  ],

  LEADERS: [
    {
      icon: "https://d48f7equ64qjl.cloudfront.net/static_files/team_pictures/Amra_Tareen.jpg",
      name: "Amra Tareen",
      title: "CEO",
      linkedin: "https://www.linkedin.com/in/amra-tareen-51a8919/",
    },
    {
      icon: "https://d48f7equ64qjl.cloudfront.net/static_files/team_pictures/Murad_Akhter.png",
      name: "Murad Akhter",
      title: "Co-Founder",
      linkedin: "https://www.linkedin.com/in/muradakhter/",
    },
  ],

  TEAM: [
    {
      icon: "https://d48f7equ64qjl.cloudfront.net/static_files/team_pictures/Andrew Ustich.jpg",
      name: "Andrew Ustich",
      title: "",
      linkedin: "",
    },
    {
      icon: "https://d48f7equ64qjl.cloudfront.net/static_files/team_pictures/Abdul_Wasay.jpg",
      name: "Abdul Wasay",
      title: "",
      linkedin: "",
    },
    {
      icon: "https://d48f7equ64qjl.cloudfront.net/static_files/team_pictures/Abdullah_Malik.jpg",
      name: "Abdullah Malik",
      title: "",
      linkedin: "",
    },
    {
      icon: "https://d48f7equ64qjl.cloudfront.net/static_files/team_pictures/Abdullah_Nizami.jpg",
      name: "Abdullah Nizami",
      title: "",
      linkedin: "",
    },
    {
      icon: "https://d48f7equ64qjl.cloudfront.net/static_files/team_pictures/AineMuhammad.jpg",
      name: "Ain e Muhammad",
      title: "",
      linkedin: "",
    },
    {
      icon: "https://d48f7equ64qjl.cloudfront.net/static_files/team_pictures/Amanda Boldin.JPG",
      name: "Amanda Boldin",
      title: "",
      linkedin: "",
    },
    {
      icon: "https://d48f7equ64qjl.cloudfront.net/static_files/team_pictures/Asad Awan.jpg",
      name: "Asad Awan",
      title: "",
      linkedin: "",
    },
    {
      icon: "https://d48f7equ64qjl.cloudfront.net/static_files/team_pictures/Benish Hussain.jpeg",
      name: "Benish Hussain",
      title: "",
      linkedin: "",
    },
    {
      icon: "https://d48f7equ64qjl.cloudfront.net/static_files/team_pictures/Danil Kuznetsov.jpg",
      name: "Danil Kuznetsov",
      title: "",
      linkedin: "",
    },
    {
      icon: "https://d48f7equ64qjl.cloudfront.net/static_files/team_pictures/David Denenberg.jpeg",
      name: "David Denenberg",
      title: "",
      linkedin: "",
    },
    {
      icon: "https://d48f7equ64qjl.cloudfront.net/static_files/team_pictures/Elora Braeson.jpg",
      name: "Elora Braeson",
      title: "",
      linkedin: "",
    },
    {
      icon: "https://d48f7equ64qjl.cloudfront.net/static_files/team_pictures/Faizan_urRehman.png",
      name: "Faizan ur Rehman",
      title: "",
      linkedin: "",
    },
    {
      icon: "https://d48f7equ64qjl.cloudfront.net/static_files/team_pictures/Gene Clark.PNG",
      name: "Gene Clark",
      title: "",
      linkedin: "",
    },
    {
      icon: "https://d48f7equ64qjl.cloudfront.net/static_files/team_pictures/Huzaifa_Hussnain.jpg",
      name: "Huzaifa Hussnain",
      title: "",
      linkedin: "",
    },
    {
      icon: "https://d48f7equ64qjl.cloudfront.net/static_files/team_pictures/Irtiza_Sohail.png",
      name: "Irtiza Sohail",
      title: "",
      linkedin: "",
    },
    {
      icon: "https://d48f7equ64qjl.cloudfront.net/static_files/team_pictures/Ismail Cetiner.jpg",
      name: "Ismail Cetiner",
      title: "",
      linkedin: "",
    },
    {
      icon: "https://d48f7equ64qjl.cloudfront.net/static_files/team_pictures/Lindsay Messina.jpg",
      name: "Lindsay Messina",
      title: "",
      linkedin: "",
    },
    {
      icon: "https://d48f7equ64qjl.cloudfront.net/static_files/team_pictures/Louis Trieu.jpg",
      name: "Louis Trieu",
      title: "",
      linkedin: "",
    },
    {
      icon: "https://d48f7equ64qjl.cloudfront.net/static_files/team_pictures/Mahnoor_Khan.jpg",
      name: "Mahnoor Khan",
      title: "",
      linkedin: "",
    },
    {
      icon: "https://d48f7equ64qjl.cloudfront.net/static_files/team_pictures/Mehar_Fatima.jpg",
      name: "Mehar Fatima",
      title: "",
      linkedin: "",
    },
    {
      icon: "https://d48f7equ64qjl.cloudfront.net/static_files/team_pictures/Melnyk Liudmyla.jpg",
      name: "Melnyk Liudmyla",
      title: "",
      linkedin: "",
    },
    {
      icon: "https://d48f7equ64qjl.cloudfront.net/static_files/team_pictures/Melnyk Oleh.jpg",
      name: "Melnyk Oleh",
      title: "",
      linkedin: "",
    },
    {
      icon: "https://d48f7equ64qjl.cloudfront.net/static_files/team_pictures/Mukarram.jpeg",
      name: "Mukarram Shafique",
      title: "",
      linkedin: "",
    },
    {
      icon: "https://d48f7equ64qjl.cloudfront.net/static_files/team_pictures/Nauman_Minhas.jpg",
      name: "Nauman Minhas",
      title: "",
      linkedin: "",
    },
    {
      icon: "https://d48f7equ64qjl.cloudfront.net/static_files/team_pictures/Seerat_Kamal.jpg",
      name: "Seerat Kamal",
      title: "",
      linkedin: "",
    },
    {
      icon: "https://d48f7equ64qjl.cloudfront.net/static_files/team_pictures/Serhii Bohoslavets.jpg",
      name: "Sergey Bohoslavets",
      title: "",
      linkedin: "",
    },
    {
      icon: "https://d48f7equ64qjl.cloudfront.net/static_files/team_pictures/Serhii Marola.jpg",
      name: "Serhii Marola",
      title: "",
      linkedin: "",
    },
    {
      icon: "https://d48f7equ64qjl.cloudfront.net/static_files/team_pictures/Serhii Oleksin.jpg",
      name: "Serhii Oleksin",
      title: "",
      linkedin: "",
    },
    {
      icon: "https://d48f7equ64qjl.cloudfront.net/static_files/team_pictures/Shahbano Khan.png",
      name: "Shahbano Khan",
      title: "",
      linkedin: "",
    },
    {
      icon: "https://d48f7equ64qjl.cloudfront.net/static_files/team_pictures/Viktoriia_Kuzmenko.jpg",
      name: "Viktoriia Kuzmenko",
      title: "",
      linkedin: "",
    },
    {
      icon: "https://d48f7equ64qjl.cloudfront.net/static_files/team_pictures/Yuliya Saiko.jpg",
      name: "Yuliya Saiko",
      title: "",
      linkedin: "",
    },
    {
      icon: "https://d48f7equ64qjl.cloudfront.net/static_files/team_pictures/Yuriy Petrichenko.jpg",
      name: "Yuriy Petrichenko",
      title: "",
      linkedin: "",
    },
  ],

  INTEGRATIONS: {
      title: "Integrations",
      subText: "ALL3D offers integrations with various e-commerce platforms. Click on each one to learn more.",
      cardTitle: "Amazon",
      cardSubText: "Manage your Amazon product's content through ALL3D",
      buttonText: "Details"
  },

  AMAZON_INTEGRATION: {
    title: "Manage your Amazon product's content through ALL3D",
    detailsText: "At ALL3D, we understand the importance of presenting your products in the best possible light on Amazon. Our new integration takes the hassle out of managing your product assets by allowing you to upload high-quality 2D and 3D content directly to your Amazon Product Detail Pages (PDP) from our platform. No more manual downloads and uploads—just seamless content management that saves you time and enhances your efficiency. Let us help you showcase your products in a way that captures attention and drives sales.",
    buttonTitleText: "Usage is free for ALL3D enterprise customers. Integration can be completed directly through the ALL3D portal",
    buttonText:"Get Started"
  },

  FAQS: [
    {
      Q: "Can I change my subscription?",
      A: (
        <span>
          Yes, you can upgrade your subscription at any time. You will be
          charged for the new subscription immediately for the next 30 days and
          then per month thereafter. To upgrade your subscription, kindly follow
          this <a href="https://app.all3d.ai/account-details">link</a>. To
          downgrade your subscription, please cancel your subscription and when
          the original subscription month is over you can sign up for a new
          plan. If this does not work for you, please{" "}
          <a href="/contact-us">contact us</a>.
          <br />
          <br />
          For enterprise plans, please contact your account manager for any
          subscription changes.
        </span>
      ),
    },
    {
      Q: "Can I cancel my subscription if it's not working for me?",
      A: (
        <span>
          Yes, you can cancel your subscription at anytime and you will not be
          charged for the following billing cycle.
          <br />
          <br />
          For enterprise plans, please contact your account manager as
          subscription cancellation details are included in your subscription
          agreement.
        </span>
      ),
    },
    {
      Q: "What is the refund policy?",
      A: (
        <span>
          All transactions are final, however we have a happiness guarantee and
          if there is any issues with your purchase please contact our customer
          support team for assistance.
        </span>
      ),
    },
    {
      Q: "How do I use the ALL3D platform?",
      A: (
        <span>
          We have video tutorials and step-by-step guides which you can view{" "}
          <a href="/how-all3d-works">here</a>.
        </span>
      ),
    },
  ],

  STOREFRONTS: {
    title: "Immersive Storefronts",
    heading: "Customize Your Image",
    _store: {
      title: "Present your products",
      text: "Use 3D photography, 360° images, and videos to bring your products to life.",
    },
    _lifestyle: {
      title: "Lifestyle Images",
      text: "Let your customers see themselves using your products through customized lifestyles or room scenes, seeing products being used.",
    },
    _space: {
      title: "3D Spaces and Showrooms",
      text: "Design virtual showrooms in hours instead of spending weeks creating them in real life.",
    },
    _virtual_tour: {
      title: "Virtual Tours",
      text: " Use the latest 3D technology to put your best foot forward and deliver memorable 3D tours.",
    },
  },
  HIGH_POINT_TITLE:
    "Personalization and 3D Virtualization platform for Ecommerce",
  HIGH_POINT_DESCRIPTION_1:
    "Until now your showrooms were limited by physical real estate and could only show limited selection of products in a designed room setting.",
  HIGH_POINT_DESCRIPTION_2:
    "With ALL3D we make it possible at a fraction of the cost and time to convert all of your products into 3D and showcase them in reusable personalized showrooms or room scenes for ecommerce.",

  DESCRIPTION: {
    text_1: {
      p1: "ALL3D has provided Uttermost with a cost-effective and efficient solution for displaying our products in lifestyle settings that we wouldn't otherwise be able to achieve. We've been so impressed with",
      p2: "their ability to quickly turn around product models and create custom room scenes that further enhance our products.",
    },
    text_2: {
      name: " – Laura Scott,",
      designation: "Director of Ecommerce, Uttermost",
    },
    consultation_btn_text: "Schedule a Free Consultation",
  },

  HIGH_POINT_FOOTER: {
    phone: "(415) 320-7528",
    address: "541 19th Ave, San Francisco, CA 94121",
  },
};

export default TextConstants;
