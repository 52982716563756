import React, { useEffect, useState } from "react";
import axios from "axios";
import NavBar from "../landingPage/header/navbar";
import { BLOGS_CONTROLLER, BASE_URL } from "../../env";
import { withRouter } from "react-router-dom";
import { FileTextOutlined } from "@ant-design/icons";
import DottedLoader from "../DottedLoader/DottedLoader.jsx";
import Footer from "../landingPage/footer";
import "./index.scss";

const Blogs = ({ history }) => {
  const [blogsList, setBlogsList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    return date.toLocaleDateString("en-US", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
  };

  const fetchBlogs = async () => {
    try {
      const response = await axios.post(BLOGS_CONTROLLER, {
        action: "get_active_blogs",
      });
      const blogs = response.data.body;
      setBlogsList(blogs);
    } catch (err) {
      setError("Failed to fetch blog data.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBlogs();
  }, []);

  const handleBlogClick = (id) => {
    window.location.href = `/blog/${id}`;
  };

  if (loading) {
    return (
      <div className="loader">
        <NavBar />
        <DottedLoader />
      </div>
    );
  }

  if (error) {
    return (
      <div className="error">
        <NavBar />
        <p>{error}</p>
      </div>
    );
  }

  return (
    <div>
      <NavBar />
        {blogsList.length === 0 ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <div className="no-blogs" style={{ textAlign: 'center' }}>
            <FileTextOutlined className="no-blogs-icon" />
            <p className="no-blogs-text">
              No blog available at the moment. Check back later!
            </p>
          </div>
        </div>        
        ) : (
          <div className="blogs-container">
          <div className="blogs-list">
            {blogsList.map((blog) => (
              <div
                key={blog.id}
                className="blog-item font graphik-regular"
                onClick={() => handleBlogClick(blog.slug)}
              >
                <img
                  src={BASE_URL + blog.image_url}
                  alt={blog.title}
                  className="blog-image"
                />
                <div className="blog-details">
                  <h2 className="font graphik-medium blog-title">
                    {blog.title}
                  </h2>
                  <div className="blog-meta">
                    <p className="blog-author">
                      By <span className="author-name">{blog.author}</span>
                    </p>
                    <p className="blog-date">{formatDate(blog.created_at)}</p>
                  </div>
                  <p className="blog-description">{blog.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        )}
      <Footer />
    </div>
  );
};

export default withRouter(Blogs);
