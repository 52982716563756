import React from "react";
import { useEffect } from "react";
import $ from "jquery";
import "./index.scss";
import { ArrowRightOutlined } from "@ant-design/icons";
import { Image } from "antd";
import FileConstants from "../../../FileConstants";
import TextConstants from "../../../TextConstants";
import LazyLoad from "react-lazyload";

const HolisticSolution = () => {
  return (
    <div className="container-fluid holistic-sol-container">
      <WebVersion />
      <MobileVersion />
    </div>
  );
};

const {
  title,
  title_section_1,
  list_section_1,
  title_section_2,
  text_section_2,
  btn_text,
  title_section_3,
  text_section_3,
  title_section_4,
  text_section_4,
  title_section_5,
  list_section_5,
} = TextConstants.HOLISTIC_SOLUTION;
const WebVersion = () => {
  return (
    <div className="d-none d-lg-block">
      <div className="row justify-content-md-center">
        <div className="col-lg-10 col-12 holistic-sol" id="textSection">
          <h1
            className="font lora f-32 ws-6 w-400 black-2b justify-in-center"
            style={{ marginBottom: 15 }}
          >
            {title}
          </h1>
        </div>
        <div className="col-lg-24 col-12" style={{ padding: 0 }}>
          <div
            id="holisticCarousel"
            className="carousel slide slide-bg"
            data-ride="carousel"
          >
            <ul className="carousel-indicators">
              <li
                data-target="#holisticCarousel"
                data-slide-to="0"
                className="active"
              ></li>
              <li data-target="#holisticCarousel" data-slide-to="1"></li>
              <li data-target="#holisticCarousel" data-slide-to="2"></li>
              <li data-target="#holisticCarousel" data-slide-to="3"></li>
              <li data-target="#holisticCarousel" data-slide-to="4"></li>
            </ul>

            <div id="holisticContainer" className="carousel-inner holistic-sol">
              <div className="carousel-item active">
                <div className="justify-space-between">
                  <div className="col-lg-6 col-12">
                    {/* <div className='slide-num' style={{marginBottom: 20}}>
									<span className='font lora f-24 w-600 brown-b1'>1</span>
								</div> */}
                    {/* <h4 className='font graphik-medium f-18 w-500 black-2b'>{title_section_1}</h4> */}
                    <ul className="font graphik-regular f-16 w-400 grey-light list-pd">
                      {list_section_1.map((x) => (
                        <li>{x}</li>
                      ))}
                    </ul>
                  </div>
                  <div className="col-lg-6 col-12 slide-1-col">
                    <img
                      className="slide-1-img load-lazy"
                      src={FileConstants.HOLISTIC_SECTION_1}
                      height="512px"
                      alt="Bed"
                    />
                  </div>
                </div>
              </div>
              <div className="carousel-item">
                <div className="justify-space-between">
                  <div className="col-lg-6 col-12">
                    {/* <div className='slide-num' style={{marginBottom: 20}}>
									<span className='font lora f-24 w-600 brown-b1'>2</span>
								</div> */}
                    <h4 className="font graphik-medium f-18 w-500 black-2b">
                      {title_section_2}
                    </h4>
                    <p className="font graphik-regular f-16 w-400 grey-light ">
                      {text_section_2}
                    </p>
                    <a
                      href="https://app.all3d.ai/product_public_viewer/product_3d_model/10754"
                      target="_blank"
                    >
                      <button
                        type="button"
                        className="btn see-example-btn font graphik-regular f-14 w-500 white"
                      >
                        {btn_text} <ArrowRightOutlined />
                      </button>
                    </a>
                  </div>
                  <div className="col-lg-6 col-12 justify-in-center wrap">
                    {FileConstants.HOLISTIC_SECTION_2.map((img_src, index) => (
                      <div className="col-6">
                        <LazyLoad>
                          <img
                            className="slide-2-img load-lazy"
                            src={img_src}
                            height="237px"
                            alt="Chair1"
                          />
                        </LazyLoad>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="carousel-item">
                <div className="justify-space-between">
                  <div className="col-lg-6 col-12">
                    {/* <div className='slide-num' style={{marginBottom: 20}}>
									<span className='font lora f-24 w-600 brown-b1'>3</span>
								</div> */}
                    <h4 className="font graphik-medium f-18 w-500 black-2b">
                      {title_section_3}
                    </h4>
                    <p className="font graphik-regular f-16 w-400 grey-light ">
                      {text_section_3}
                    </p>
                  </div>
                  <div className="col-lg-6 col-12 justify-in-center wrap">
                    {FileConstants.HOLISTIC_SECTION_3.map((img_src, index) => (
                      <div
                        className="col-12"
                        style={{ marginBottom: index == 0 ? 30 : 0 }}
                      >
                        <LazyLoad>
                          <img
                            className="slide-3-img load-lazy"
                            src={img_src}
                            height="237px"
                            alt="Chair1"
                          />
                        </LazyLoad>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="carousel-item">
                <div className="row justify-content-md-center">
                  <div
                    className="col-lg-12 col-12 justify-in-center"
                    style={{ marginBottom: 30 }}
                  >
                    {/* <div className='slide-num' style={{marginRight: 20}}>
									<span className='font lora f-24 w-600 brown-b1'>4</span>
								</div> */}
                    <h4
                      style={{ marginBottom: 0 }}
                      className="font graphik-medium f-18 w-500 black-2b"
                    >
                      {title_section_4}
                    </h4>
                  </div>
                  <div className="col-lg-12 col-12 justify-in-center">
                    <LazyLoad>
                      <img
                        className="slide-4-img load-lazy"
                        src={FileConstants.HOLISTIC_SECTION_4}
                        alt="Chart"
                      />
                    </LazyLoad>
                  </div>
                </div>
              </div>
              <div className="carousel-item">
                <div className="row justify-content-md-center">
                  <div
                    className="col-lg-12 col-12 justify-in-center"
                    style={{ marginBottom: 30 }}
                  >
                    {/* <div className='slide-num' style={{marginRight: 20}}>
									<span className='font lora f-24 w-600 brown-b1'>5</span>
								</div> */}
                    <h4
                      style={{ marginBottom: 0 }}
                      className="font graphik-medium f-18 w-500 black-2b"
                    >
                      {title_section_5}
                    </h4>
                  </div>
                  <div className="col-lg-12 col-12 justify-in-center">
                    <LazyLoad>
                      <img
                        className="slide-5-img load-lazy"
                        src={FileConstants.HOLISTIC_SECTION_5}
                        alt="Chart"
                      />
                    </LazyLoad>
                  </div>
                  <div
                    className="col-lg-8 col-12 justify-in-center"
                    style={{ marginTop: 30 }}
                  >
                    <ul className="font graphik-regular f-16 w-400 grey-light list-pd">
                      {list_section_5.map((x) => (
                        <li>{x}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const MobileVersion = () => {
  return (
    <div className="d-lg-none">
      <div className="row justify-content-md-center">
        <div className="col-lg-10 col-12 holistic-sol" id="textSection">
          <h1
            className="font lora f-20 ws-2 w-400 black-2b justify-in-center"
            style={{ marginBottom: 12 }}
          >
            {title}
          </h1>
        </div>
        <div className="col-lg-24 col-12" style={{ padding: 0 }}>
          <div
            id="holisticCarouselMobile"
            className="carousel slide slide-bg"
            data-ride="carousel"
          >
            <ul className="carousel-indicators">
              <li
                data-target="#holisticCarouselMobile"
                data-slide-to="0"
                className="active"
              ></li>
              <li data-target="#holisticCarouselMobile" data-slide-to="1"></li>
              <li data-target="#holisticCarouselMobile" data-slide-to="2"></li>
              <li data-target="#holisticCarouselMobile" data-slide-to="3"></li>
              <li data-target="#holisticCarouselMobile" data-slide-to="4"></li>
            </ul>

            <div
              id="holisticContainerMobile"
              className="carousel-inner holistic-sol"
            >
              <div className="carousel-item active">
                <div className="col-lg-6 col-12 justify-in-center direction-flex coll">
                  {/* <h4 className='font graphik-medium f-16 w-500 black-2b' style={{textAlign: 'center'}}>{title_section_1}</h4> */}
                  <ul
                    className="font graphik-regular f-14 w-400 black-2b list-pd"
                    style={{ marginBottom: 20, textAlign: "left" }}
                  >
                    {list_section_1.map((x) => (
                      <li>{x}</li>
                    ))}
                  </ul>
                </div>
                <div className="col-lg-6 col-12">
                  <LazyLoad>
                    <img
                      className="slide-1-img load-lazy"
                      src={FileConstants.HOLISTIC_SECTION_1}
                      height="512px"
                      alt="Bed"
                    />
                  </LazyLoad>
                </div>
              </div>
              <div className="carousel-item">
                <div
                  className="col-lg-6 col-12  justify-in-center direction-flex coll"
                  style={{ marginBottom: 40 }}
                >
                  <h4
                    className="font graphik-medium f-16 w-500 black-2b"
                    style={{ textAlign: "center" }}
                  >
                    {title_section_2}
                  </h4>
                  <p
                    className="font graphik-regular f-14 w-400 black-2b"
                    style={{ textAlign: "center" }}
                  >
                    {text_section_2}
                  </p>
                  <a
                    href="https://app.all3d.ai/product_public_viewer/product_3d_model/10754"
                    target="_blank"
                  >
                    <button
                      type="button"
                      className="btn see-example-btn font graphik-regular f-14 w-600 white"
                    >
                      {btn_text} <ArrowRightOutlined />
                    </button>
                  </a>
                </div>
                <div className="col-12 justify-in-center wrap">
                  {FileConstants.HOLISTIC_SECTION_2.map((img_src, index) => (
                    <div className="col-6 col-md-4">
                      <LazyLoad>
                        <img
                          className="slide-2-img load-lazy"
                          src={img_src}
                          height="237px"
                          alt="Chair1"
                        />
                      </LazyLoad>
                    </div>
                  ))}
                </div>
              </div>
              <div className="carousel-item">
                <div
                  className="col-lg-6 col-12  justify-in-center direction-flex coll"
                  style={{ marginBottom: 6 }}
                >
                  <h4
                    className="font graphik-medium f-16 w-500 black-2b"
                    style={{ textAlign: "center" }}
                  >
                    {title_section_3}
                  </h4>
                  <p
                    className="font graphik-regular f-14 w-400 black-2b"
                    style={{ textAlign: "center" }}
                  >
                    {text_section_3}
                  </p>
                </div>
                <div className="col-lg-12 col-12 justify-in-center wrap">
                  {FileConstants.HOLISTIC_SECTION_3.map((img_src, index) => (
                    <LazyLoad>
                      <img
                        className="slide-3-img load-lazy"
                        src={img_src}
                        alt="Chair1"
                        style={{ marginBottom: index == 0 ? 10 : 0 }}
                      />
                    </LazyLoad>
                  ))}
                </div>
              </div>
              <div className="carousel-item">
                <div className="row justify-content-md-center">
                  <div
                    className="col-lg-12 col-12 justify-in-center direction-flex coll"
                    style={{ marginBottom: 0 }}
                  >
                    <h4
                      className="font graphik-medium f-16 w-500 black-2b"
                      style={{ textAlign: "center" }}
                    >
                      {title_section_4}
                    </h4>
                    <p
                      className="font graphik-regular f-14 w-400 black-2b"
                      style={{ textAlign: "center" }}
                    >
                      {text_section_4}
                    </p>
                  </div>
                  <div className="col-lg-12 col-12 justify-in-center">
                    <LazyLoad>
                      <Image
                        className="slide-4-img"
                        src={FileConstants.HOLISTIC_SECTION_4}
                        alt="Chart"
                      />
                    </LazyLoad>
                  </div>
                </div>
              </div>
              <div className="carousel-item">
                <div className="row justify-content-md-center">
                  <div
                    className="col-lg-12 col-12 justify-in-center"
                    style={{ marginBottom: 20 }}
                  >
                    <h4
                      className="font graphik-medium f-16 w-500 black-2b"
                      style={{ textAlign: "center" }}
                    >
                      {title_section_5}
                    </h4>
                  </div>
                  <div className="col-lg-12 col-12 justify-in-center">
                    <LazyLoad>
                      <Image
                        className="slide-5-img"
                        src={FileConstants.HOLISTIC_SECTION_5}
                        alt="Chart"
                      />
                    </LazyLoad>
                  </div>
                  <div
                    className="col-lg-8 col-12 justify-in-center"
                    style={{ marginTop: 16 }}
                  >
                    <ul
                      className="font graphik-regular f-14 w-400 black-2b list-pd"
                      style={{ textAlign: "left" }}
                    >
                      {list_section_5.map((x) => (
                        <li>{x}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HolisticSolution;
