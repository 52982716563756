import React from 'react';
import { Modal } from 'antd';

const SuccessModal = (props) => {

    return (
        <Modal
            className="success-modal"
            centered={true}
            closable={props.closable ? true : false}
            maskClosable={false}
            width={700}
            onCancel={props.onCancel}
            visible={props.visible}
            bodyStyle={{ padding: 0 }}
            footer={props.footer}>
            <div style={{ padding: "30px" }}>
                <div style={{ textAlign: "center" }}>
                    <img style={{ width: 85, height: 85 }} src={"/success-icon.png"}></img>
                </div>
                <div style={{ textAlign: "center", margin: "20px auto" }}>
                    <h2 className=" manrope f-16 black-00 w-600 justify-in-center">{props.heading}</h2>
                    <p className=" manrope f-14 black-55" style={{ margin: 'auto', width: '80%' }}>{props.text}</p>
                </div>
            </div>
        </Modal>
    );
}

export default SuccessModal;