import React from "react";
import { Link, NavLink } from "react-router-dom";
import "./header.scss";
import TextConstants from "../../../TextConstants";
import { PORTAL_BASE_URL } from "../../../env";
const AiCreatorCTA = ()=> {

    return (
      <div className="ai-creator-cta-container" id="aiCreatorCta">
        <img src='/ai_creator/ai-star-white.svg' className="mr-1" />
        <div className="ai-creator-banner-text">
        Generate 3D assets for ecommerce with a single image in minutes.
        </div>
        <div className="ai-creator-banner-text-mobile">
        Image to 3D assets in minutes.
        </div>
        <a className="ai-creator-link" href={`${PORTAL_BASE_URL}/register?redirectURL=ai-asset-creator`}>
            Try AI Creator
            <img src='/ai_creator/right-arrow.svg' />
        </a>
      </div>
    );
}

export default AiCreatorCTA;
