import React, { useState, useContext } from "react";
import Footer from "../landingPage/footer";
import "./AICreator.scss";
import FileConstants from "../../FileConstants";
import TextConstants from "../../TextConstants";
import MetaTagContents from "../../MetaTagContents";
import NavBar from "../landingPage/header/navbar";
import { Tabs, Row, Col, Card, Modal, Button, Carousel, Select, Collapse, Layout } from "antd";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { ArrowRightOutlined } from "@ant-design/icons";
import AICreatorContext from "../../contexts/AICreatorContext";
import SignUpModal from "./SignUpModal";
import { PORTAL_BASE_URL } from "../../env";
const { Content } = Layout;

const offerings = [
    {
        'image': '/ai_creator/sofa_ai.svg',
        'text': '3D Model',
        'icon': '/ai_creator/spin-icon.png'
    },
    {
        'image': '/ai_creator/lifestyle-image.png',
        'text': 'Lifestyle Images',
        'icon': '/ai_creator/lifestyle-icon.png'
    },
    {
        'image': '/ai_creator/phone-image.png',
        'text': 'AR view in room',
        'icon': '/ai_creator/ar-icon.png'
    }
]

const models = [
    '/ai_creator/bicycle.gif',
    '/ai_creator/watch.gif',
    '/ai_creator/chair.gif',
]

const AICreatorMobile = () => {
    const { openSignUpModal } = useContext(AICreatorContext);
    let carousel = React.createRef();

    const [showBottomBar, setShowBottomBar] = useState(false);

    const onChangeCarousel = (before, to) => {

    };

    const handleScroll = () => {
        // Define the scroll threshold at which you want the bottom bar to appear
        let buttonElement = document.getElementById('button-container');
        if (buttonElement) {
            let rect = buttonElement.getBoundingClientRect();

            const scrollThreshold = rect.top; // Adjust this value as needed
            if (scrollThreshold < 0) {
                setShowBottomBar(true);
                const element = document.querySelector('.upload-btn-bar');
                if (element) {
                    element.classList.add('show');
                }
            } else {
                setShowBottomBar(false);
            }
        }
    };

    return (

        <Content className="d-lg-none ai-background-container mt-16">
            <Row className="container-fluid ai-content-container justify-space-around pd-10">
                <Col span={24}>
                    <div className="white-bg justify-space-around mb-16">
                        <div className="manrope f-14 w-700 blue-gradient">
                            <img src='/ai_creator/ai-creator-twinkle.svg' className="mr-1" />
                            AI Creator by ALL3D
                            <span className="beta-tag">BETA</span>
                        </div>
                    </div>
                    <h1 className="manrope f-38 w-700 black-00 mb-0">
                        3D product visualization
                    </h1>
                    <h1 className="manrope f-38 w-700 blue-gradient mb-10">
                        in minutes
                    </h1>
                    <h5 className="manrope f-16 w-700 black-00">Beautiful, AI-generated 3D models, 4k product imagery, and AR for ecommerce.</h5>
                    <Carousel
                        dots={false}
                        autoplay={true}
                        ref={(node) => carousel = node}
                        className="ai-carousel"
                        beforeChange={onChangeCarousel}
                    >
                        {models.map((img, index) => (
                            <div>
                                <img
                                    className="model-image"
                                    style={{ marginTop: 21 }}
                                    src={img}
                                    alt="carousel model"
                                />
                            </div>
                        ))}
                    </Carousel>
                </Col>
            </Row>
            <Row className="mb-w-100">
                <Col span={24} className="white-bg drop-shadow pd-20 justify-in-center direction-flex coll max-w-fit-content" style={{display: 'block'}}>
                    <h3 className="manrope f-20 w-700 black-00 mb-0">
                        Get FREE, high-converting assets to use on your product page.
                    </h3>

                    <div>
                        {offerings.map((offer, index) => (
                            <Row className="justify-in-center mb-16 direction-flex coll" >
                                <Col>
                                    <img src={offer.image} className="offer-img" />
                                </Col>
                                <Col className="justify-in-center mt-10">
                                    <img src={offer.icon} className="mr-2" />
                                    <h4 className="manrope f-16 black-00 w-700 mb-0">{offer.text}</h4>
                                </Col>
                            </Row>
                        ))}
                    </div>
                    <Col span={24} className="pd-b-40">
                        <Button className="modal-okay square ai-gradient font-16 center-btn mt-16" id="button-container" onClick={() => { window.location.href = `${PORTAL_BASE_URL}/register?redirectURL=ai-asset-creator`}}>
                            <span className="mr-2">Upload an image to get started</span>
                            <ArrowRightOutlined />
                        </Button>
                    </Col>
                </Col>
            </Row>

            {showBottomBar &&
                <Row className="upload-btn-bar">
                    <Col span={24}>
                        <Button className="modal-okay square ai-gradient font-16 center-btn mt-16" onClick={() => { window.location.href = `${PORTAL_BASE_URL}/register?redirectURL=ai-asset-creator`}}>
                        {/* onClick={openSignUpModal} */}
                            <span className="mr-2">Upload an image to get started</span>
                            <ArrowRightOutlined />
                        </Button>
                    </Col>
                </Row>}
        </Content>
    );
}


export default AICreatorMobile;
