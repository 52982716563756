import React, { useState } from "react";
import Footer from "../landingPage/footer";
import "./AICreator.scss";
import FileConstants from "../../FileConstants";
import TextConstants from "../../TextConstants";
import MetaTagContents from "../../MetaTagContents";
import NavBar from "../landingPage/header/navbar";
import { Tabs, Row, Col, Card, Modal, Button, Carousel, Select, Collapse, Layout } from "antd";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { ArrowRightOutlined } from "@ant-design/icons";
import AICreatorContext from "../../contexts/AICreatorContext";
import SignUpModal from "./SignUpModal";
import AICreatorWeb from "./AICreatorWeb";
import AICreatorMobile from "./AICreatorMobile";
const { Content } = Layout;

const AICreator = () => {

    const [signUpModalVisible, setSignUpModalVisible] = useState(false);



    const openSignUpModal = () => {
        setSignUpModalVisible(true);
    }

    const closeSignUpModal = () => {
        setSignUpModalVisible(false);
    }

    return (
        <>
            <NavBar />
            <Layout>
                <AICreatorContext.Provider value={{
                    openSignUpModal: openSignUpModal,
                    closeSignUpModal: closeSignUpModal,
                    signUpModalVisible: signUpModalVisible
                }}>
                    <AICreatorWeb />
                    <AICreatorMobile />
                    <SignUpModal />
                </AICreatorContext.Provider >
            </Layout>
            <Footer/>
        </>
    );
}


export default AICreator;
