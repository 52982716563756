const MetaTagContents = {
  LANDING_PAGE: {
    title: "ALL3D",
    name: "description",
    content:
      "ALL3D provides services and solutions for you to create, customize, distribute and monetize your 3D models, virtual showrooms and photography for valuable experiences such as ecommerce and advertising across any device.",
    schemaMarkup: {
      video: {
        "@context": "https://schema.org",
        "@type": "VideoObject",
        "name": "How Our Platform Works",
        "description": "A walk through of how All3D works as a tool to generate high resolution imagery for 3D assets, visualize 3D rooms and spaces and customize products in real-time.",
        "thumbnailUrl": "https://www.youtube.com/watch?v=k6kZuZ2VS2o",
        "uploadDate": "2022-07-22",
        "duration": "PT3M46S",
        "contentUrl": "https://www.youtube.com/watch?v=k6kZuZ2VS2o",
        "embedUrl": "https://www.youtube.com/watch?v=k6kZuZ2VS2o"
      },
      organization: {
        "@context": "https://schema.org",
        "@type": "Organization",
        "address": {
          "@type": "PostalAddress",
          "addressLocality": "San Francisco, CA",
          "postalCode": "94121",
          "streetAddress": "541 19th Avenue"
        },
        "email": "amra@all3d.ai",
        "name": "ALL3D, Inc",
      }
    }
      
  },

  HOW_ALL3D_WORKS: {
    title: "How ALL3D works",
    name: "description",
    content:
      "How to create 3D visual experiences using product models, rooms, virtual studios, interior design services and request lifestyle or 3D product photography.",
    schemaMarkup: {
      virtual_product: {
        "@context": "https://schema.org/",
        "@type": "HowTo",
        "name": "Create Virtual Product",
        "image": "https://all3d.ai/7.png",
        "description": "Get started with key concepts of all the things that you can do using ALL3D platform",
        "url": "https://all3d.ai/how-all3d-works?feature=virtual_product"
      },
      generate_product_assets: {
        "@context": "https://schema.org/",
        "@type": "HowTo",
        "name": "Generate Product Assets",
        "image": "https://all3d.ai/6.png",
        "description": "Get started with key concepts of all the things that you can do using ALL3D platform",
        "url": "https://all3d.ai/how-all3d-works?feature=generate_product_assets"
      },
      generate_product_variants: {
        "@context": "https://schema.org/",
        "@type": "HowTo",
        "name": "Generate Product Variants",
        "image": "https://all3d.ai/5.png",
        "description": "Get started with key concepts of all the things that you can do using ALL3D platform",
        "url": "https://all3d.ai/how-all3d-works?feature=generate_product_variants"
      },
      use_all3d_store: {
        "@context": "https://schema.org/",
        "@type": "HowTo",
        "name": "Use ALL3D Store",
        "image": "https://all3d.ai/1.png",
        "description": "Get started with key concepts of all the things that you can do using ALL3D platform",
        "url": "https://all3d.ai/how-all3d-works?feature=use_all3d_store"
      },
      create_virtual_space: {
        "@context": "https://schema.org/",
        "@type": "HowTo",
        "name": "Create Virtual Space",
        "image": "https://all3d.ai/2.png",
        "description": "Get started with key concepts of all the things that you can do using ALL3D platform",
        "url": "https://all3d.ai/how-all3d-works?feature=create_virtual_space"
      },
      create_3d_tours: {
        "@context": "https://schema.org/",
        "@type": "HowTo",
        "name": "Create 3D Tours",
        "image": "https://all3d.ai/3.png",
        "description": "Get started with key concepts of all the things that you can do using ALL3D platform",
        "url": "https://all3d.ai/how-all3d-works?feature=create_3d_tours"
      },
      create_lifestyle_images: {
        "@context": "https://schema.org/",
        "@type": "HowTo",
        "name": "Create Lifestyle Images",
        "image": "https://all3d.ai/4.png",
        "description": "Get started with key concepts of all the things that you can do using ALL3D platform",
        "url": "https://all3d.ai/how-all3d-works?feature=create_lifestyle_images"
      }
    }

  },

  CONTACT_US_FORM: {
    title: "ALL3D - Contact Us",
    name: "description",
    content:
      "Reach out to the ALL3D team to discuss customized pricing plans or get access to try out 3D photography and modelling services.",
  },

  STORE: {
    title: "ALL3D - Buy 3D Models",
    name: "description",
    content: "Buy or find free 3D product and room models and designed scenes.",
    schemaMarkup: {
      "@context": "https://schema.org",
      "@type": "Store",
      "name": "Virtual product Store",
      "paymentAccepted": "Online",
      "url": "https://all3d.ai/",
      "description": "Buy or find free 3D product and room models and designed scenes"
    }
  },

  SUBSCRIPTION_PLANS: {
    title: "ALL3D - Pricing Plans and Subscriptions",
    name: "description",
    content:
      "Check out our transparent and value-driven pricing and subscription plans for 3D modelling, photography and design services including free plans.",
  },

  QUICKLOOK: {
    title: "ALL3D - Quicklook",
    name: "description",
    content:
      "3D models created by the ALL3D.ai platform for immersive, real-world experiences. Contact us today to bring your products to life in brilliant 3D!",
  },

  UNIT_PRICE: {
    title: "Detailed Pricing for 3D Photography and Design Services",
    name: "description",
    content:
      "Prices and costs for product and room models, 3D photography, lifestyle photography and design services.",
  },

  SCENE_DETAILS: {
    title: "Buy Interior Design Spaces",
    name: "description",
    content:
      "Purchase ready made designed rooms in 3D for use in real estate staging or interior design.",
  },

  SPACE_DETAILS: {
    title: "Buy 3D Room Models",
    name: "description",
    content:
      "Purchase ready made 3D room models for use in photography or your interior designs.",
  },

  FAQS: {
    title: "ALL3D - FAQs",
    name: "description",
    content:
      "Information on changing or cancelling subscription plans for 3D services",
    schemaMarkup:{
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": [{
          "@type": "Question",
          "name": "Can I change my subscription?",
          "acceptedAnswer": {
          "@type": "Answer",
          "text": "Yes, you can upgrade your subscription at any time. You will be charged for the new subscription immediately for the next 30 days and then per month thereafter. To upgrade your subscription, kindly follow this link. To downgrade your subscription, please cancel your subscription and when the original subscription month is over you can sign up for a new plan. If this does not work for you, please contact us. For enterprise plans, please contact your account manager for any subscription changes."
          }
      },{
          "@type": "Question",
          "name": "Can I cancel my subscription if it's not working for me?",
          "acceptedAnswer": {
          "@type": "Answer",
          "text": "Yes, you can cancel your subscription at anytime and you will not be charged for the following billing cycle. For enterprise plans, please contact your account manager as subscription cancellation details are included in your subscription agreement."
          }
      },{
          "@type": "Question",
          "name": "What is the refund policy?",
          "acceptedAnswer": {
          "@type": "Answer",
          "text": "All transactions are final, however we have a happiness guarantee and if there is any issues with your purchase please contact our customer support team for assistance."
          }
      },{
          "@type": "Question",
          "name": "How do I use the ALL3D platform?",
          "acceptedAnswer": {
          "@type": "Answer",
          "text": "We have video tutorials and step-by-step guides which you can view here."
          }
      }]
    }
  },

  ABOUT_US: {
    title: "ALL3D - About Us",
    name: "description",
    content:
      "We are a global team of innovators who believe that our physical world is about to be digitized in three dimensions and are working on a cutting-edge platform to facilitate mass adoption of 3D by helping companies with affordable and quick 3D photography and design services.",
  },

  PRIVACY_AND_TERMS: {
    title: "Terms of Use",
    name: "description",
    content:
      "Usage policies for 3D models, photography and virtual showroom services.",
  },

  PRODUCT_DETAILS: {
    title: "Buy 3D Product Models",
    name: "description",
    content:
      "Purchase ready made 3D product models for use in photography or your interior designs.",
  },

  SKYLINE_BOHO_RENDERS: {
    title: "ALL3D - Skyline Modern Boho Renders",
    name: "description",
    content: "Skyline Modern Boho Renders",
  },

  SKYLINE_DECO_RENDERS: {
    title: "ALL3D - Skyline Deco Glam Renders",
    name: "description",
    content: "Skyline Deco Glam Renders",
  },

  PREPPY_RENDERS: {
    title: "ALL3D - Preppy Traditional Renders",
    name: "description",
    content: "Preppy Traditional Renders",
  },

  FARMHOUSE_RENDERS: {
    title: "ALL3D - Farmhouse Renders",
    name: "description",
    content: "Farmhouse Renders",
  },

  PRIVACY: {
    title: "ALL3D - Privacy",
    name: "description",
    content: "Check our Privacy Policy.",
  },

  TERMS: {
    title: "ALL3D - Terms",
    name: "description",
    content: "Check our terms.",
  },

  PRICING_PAGE: {
    title: "ALL3D - Pricing",
    name: "description",
    content:
      "We believe in transparency and a disruptive pricing model when it comes to 3D modeling and services. Check our pricing.",
  },

  ABOUT_US_INDEX: {
    title: "ALL3D - About Us",
    name: "description",
    content:
      "We are a team of innovators who believe that our physical world is about to be digitized in three dimensions. Advances in technology will soon make our products, spaces and eventually our digital avatars widely available in digital 3D form. We want to facilitate the mass-market adoption of 3D ecommerce. We provide technology to help companies large and small create 3D digital content that builds brands, allows the creation of immersive marketing media and increases sales conversion.",
  },

  LIFESTYLES_FEED: {
    title: "ALL3D - Lifestyles Feed",
    name: "robots",
    content: "noindex",
  },

  INTEGRATIONS: {
    title: "Integrations",
  },

  TERMS_AND_CONDITIONS: {
    title: "ALL3D - Terms and Condtions",
  },
};

export default MetaTagContents;
