import React, { useState, useContext } from "react";
import Footer from "../landingPage/footer";
import "./AICreator.scss";
import FileConstants from "../../FileConstants";
import TextConstants from "../../TextConstants";
import MetaTagContents from "../../MetaTagContents";
import NavBar from "../landingPage/header/navbar";
import { Tabs, Row, Col, Card, Modal, Button, Carousel, Select, Collapse, Layout, Form, Input } from "antd";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { ArrowRightOutlined, LoadingOutlined } from "@ant-design/icons";
import AICreatorContext from "../../contexts/AICreatorContext";
import SuccessModal from "../SuccessModal/SuccessModal";

const { Option } = Select;


const company_size = [
    '1-10',
    '11-50',
    '51-200',
    '201-500',
    '501-1000',
    '1001-5000',
    'Over 5000',
]

const business_types = [
    'Brand (DTC or Seller)',
    'Retailer',
    'Marketplace',
    'Manufacturer',
    'Interior Designer',
    'Real Estate',
    'Individual',
    'Other'
]
const SignUpModal = () => {
    const { signUpModalVisible, closeSignUpModal } = useContext(AICreatorContext);

    const [buttonLoader, setButtonLoader] = useState(false);
    const [successModalVisible, setSuccessModalVisible] = useState(false);

    const [form] = Form.useForm();

    const onFinishForm = (values) => {
        console.log(values)
    }

    const onCancelSuccessModal = () => {
        setSuccessModalVisible(false);
    }

    return (
        <>
            <Modal
                className="signup-modal"
                width={600}
                destroyOnClose={true}
                visible={signUpModalVisible}
                closable={true}
                onCancel={closeSignUpModal}
                title={<span className="manrope f-24 w-700">Sign up to try ALL3D for free</span>}>
                <h3 className="manrope f-14 w-500 mb-16">Your AI-generated 3D models and images will be available to view and download from your ALL3D account dashboard.</h3>
                <Form layout="horizontal"
                    form={form}
                    name="signup-form"
                    onFinish={onFinishForm}
                    scrollToFirstError>
                    <Row>
                        <Col span={24}><h4 className="manrope f-16 w-700 mb-10">Your Info</h4></Col>
                        <Col span={12}>
                            <Form.Item name="first_name" className="mr-6"
                                rules={[{
                                    required: true,
                                    message: 'Please enter your first name'
                                }]}>
                                <Input placeholder="First name" className="manrope f-14 w-400 pd-16" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="last_name" rules={[{
                                required: true,
                                message: 'Please enter your last name'
                            }]}>
                                <Input placeholder="Last name" className="manrope f-14 w-400 pd-16" />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name="company_email"
                                rules={[
                                    {
                                        type: 'email',
                                        message: 'Please enter a valid company email',
                                    },
                                    {
                                        required: true,
                                        message: 'Please enter your company email',
                                    },
                                ]}>
                                <Input placeholder="Company email" className="manrope f-14 w-400 pd-16" />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name="company_name"
                                rules={[{
                                    required: true,
                                    message: "Please enter your company's name"
                                }]}>
                                <Input placeholder="Company name" className="manrope f-14 w-400 pd-16" />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name="company_size" className="signup-select"
                                rules={[{
                                    required: true,
                                    message: 'Please select the size of your company'
                                }]}>
                                <Select showSearch placeholder="Company size" className="manrope f-14 w-400">
                                    {company_size.map((x, index) => (
                                        <Option value={x} key={index} className="manrope f-14 w-400">
                                            {x}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item name="business_type" className="signup-select"
                                rules={[{
                                    required: true,
                                    message: "Please select the type of your business"
                                }]}>
                                <Select showSearch placeholder="Type of business" className="manrope f-14 w-400">
                                    {business_types.map((x, index) => (
                                        <Option value={x} key={index} className="manrope f-14 w-400">
                                            {x}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}><h4 className="manrope f-16 w-700 mb-10">Login Info</h4></Col>
                        <Col span={24}>
                            <Form.Item name="username" rules={[{
                                required: true,
                                message: 'Please enter a username'
                            }]}>
                                <Input placeholder="Username" className="manrope f-14 w-400 pd-16" />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name="password"
                                rules={[{
                                    required: true,
                                    message: 'Please enter password',
                                }]} hasFeedback>
                                <Input.Password placeholder="Password" className="manrope f-14 w-400 pd-16" />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name="confirm_password" rules={[
                                {
                                    required: true,
                                    message: 'Please confirm your password!',
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                    },
                                }),
                            ]} dependencies={['password']} hasFeedback>
                                <Input.Password placeholder="Retype password" className="manrope f-14 w-400 pd-16" />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <p className="manrope f-12 w-400 black-73">By creating an account, you accept ALL3D's <a href="/terms" target="_blank">Terms of Use</a> and <a href="/privacy" target="_blank">Privacy Policy</a>.</p>
                        </Col>

                        <Col span={24}>
                            <Form.Item>
                                <Button id="sign_up" className="modal-okay square font-16 center-btn pdt-16" htmlType="submit">
                                    Sign Up{buttonLoader ? <LoadingOutlined /> : ''}
                                </Button>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <p className="manrope f-14 w-500 black-00 text-center">Already have an ALL3D account? <span className="manrope f-14 w-400 blue hover-change">Log in</span></p>
                        </Col>
                    </Row>
                </Form>
            </Modal>
            <SuccessModal
             visible={successModalVisible}
             heading={"You are signed up!"}
             text={""}
             footer={[
                <></>
             ]}
            />
        </>
    );
}


export default SignUpModal;
