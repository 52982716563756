import React, { useState, useContext } from "react";
import Footer from "../landingPage/footer";
import "./AICreator.scss";
import FileConstants from "../../FileConstants";
import TextConstants from "../../TextConstants";
import MetaTagContents from "../../MetaTagContents";
import NavBar from "../landingPage/header/navbar";
import { Tabs, Row, Col, Card, Modal, Button, Carousel, Select, Collapse, Layout } from "antd";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { ArrowRightOutlined } from "@ant-design/icons";
import AICreatorContext from "../../contexts/AICreatorContext";
import SignUpModal from "./SignUpModal";
import { PORTAL_BASE_URL } from "../../env";
const { Content } = Layout;

const offerings = [
    {
        'image': '/ai_creator/sofa_ai.svg',
        'text': '3D Model',
        'icon': '/ai_creator/spin-icon.png'
    },
    {
        'image': '/ai_creator/lifestyle-image.png',
        'text': 'Lifestyle Images',
        'icon': '/ai_creator/lifestyle-icon.png'
    },
    {
        'image': '/ai_creator/phone-image.png',
        'text': 'AR view in room',
        'icon': '/ai_creator/ar-icon.png'
    }
]

const models = [
    '/ai_creator/bicycle.gif',
    '/ai_creator/watch.gif',
    '/ai_creator/chair.gif',
]


const AICreatorWeb = () => {
    const { openSignUpModal } = useContext(AICreatorContext);
    let carousel = React.createRef();

    const onChangeCarousel = (before, to) => {

    };

    return (

        <Content className="d-none d-lg-block ai-background-container">
            <Row className="container-fluid ai-content-container justify-space-around align-flex-start">
                <Col span={12}>
                    <div className="white-bg justify-space-around mb-16">
                        <div className="manrope f-14 w-700 blue-gradient">
                            <img src='/ai_creator/ai-creator-twinkle.svg' className="mr-1" />
                            AI Creator by ALL3D
                            <span className="beta-tag">BETA</span>
                        </div>
                    </div>
                    <h1 className="manrope f-50 black-00 mb-0">
                        3D product visualization

                    </h1>
                    <h1 className="manrope f-50 blue-gradient mb-16">
                        in minutes
                    </h1>
                    <h5 className="manrope f-16 w-500 black-00 mb-42">Beautiful, AI-generated 3D models, 4k product imagery, and AR for ecommerce.</h5>
                    <Carousel
                        dots={false}
                        autoplay={true}
                        ref={(node) => carousel = node}
                        className="ai-carousel"
                        beforeChange={onChangeCarousel}
                    >
                        {models.map((img, index) => (
                            <div>
                                <img
                                    className="model-image"
                                    src={img}
                                    alt="carousel model"
                                />
                            </div>
                        ))}
                    </Carousel>
                </Col>
                <Col span={12} className="white-bg drop-shadow pd-40-56 justify-in-center direction-flex coll max-w-fit-content">
                    <h3 className="manrope f-20 w-700 black-00 mb-0">
                        Get FREE, high-converting assets to use
                    </h3>
                    <h3 className="manrope f-20 w-700 black-00">
                        on your product page.
                    </h3>
                    <div>
                        {offerings.map((offer, index) => (
                            <Row className="justify-in-start mb-16">
                                <Col>
                                    <img src={offer.image} className="offer-img" />
                                </Col>
                                <Col className="justify-in-center ml-4">
                                    <img src={offer.icon} className="mr-2" />
                                    <h4 className="manrope f-16 black-00 w-700 mb-0">{offer.text}</h4>
                                </Col>
                            </Row>
                        ))}
                    </div>

                    <Button className="modal-okay square ai-gradient font-16 center-btn mt-16" 
                    onClick={() => { window.location.href = `${PORTAL_BASE_URL}/register?redirectURL=ai-asset-creator`}}>
                    {/* onClick={openSignUpModal} */}
                        <span className="mr-2">Upload an image to get started</span>
                        <ArrowRightOutlined />
                    </Button>
                </Col>
            </Row>
        </Content>
    );
}


export default AICreatorWeb;
