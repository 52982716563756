import React from "react";
import { Link, NavLink } from "react-router-dom";
import "./header.scss";
import $ from "jquery";
import TextConstants from "../../../TextConstants";
import AiCreatorCTA from "./aiCreatorCTA";
class NavBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      navBarClass: "",
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", () => {
      let elmnt = document.getElementsByClassName("threeDImage");
      if (elmnt && elmnt[0] && elmnt[0].offsetHeight) {
        let updatedElem =
          window.document.getElementsByClassName("navbar-light");
        if (updatedElem && updatedElem[0]) {
          if (window.scrollY > elmnt[0].offsetHeight) {
            updatedElem[0].classList.add("full-opacity");
          } else {
            updatedElem[0].classList.remove("full-opacity");
          }
        }
      }
    });

    $(function () {
      //Commenting this code bit for now
      $(".navbar-toggler").on("click", function () {
        // $(".rel").toggleClass("menu-open");
        // $(".ant-layout").toggleClass("menu-open");
      });
    });
  }

  render() {
    const { items } = TextConstants.NAVBAR;
    return (
      <div className="custom-nav" id="topheader">
        <AiCreatorCTA/>
        <nav className="navbar navbar-expand-lg navbar-light pt-2 justify-space-between navbar-border-bottom">
          <Link className="navbar-brand" to={"/"}>
            <img src="/all3d-new-logo-red.svg" width="105px" height="35px" alt="ALL3D" />
          </Link>
          <button
            className="navbar-toggler collapsed"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="my-1 mx-2 close">
              <img src="/cross.svg" alt="cross icon" />
            </span>
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto"></ul>
            <ul className="navbar-nav">
              <li className={`links nav-item m-auto text-settings`}>
                <NavLink
                  className="nav-link header-text font manrope-f f-16 w-700 blue-gradient"
                  style={{ lineHeight: "0", paddingTop: 0, paddingBottom: 0 }}
                  activeClassName="active"
                  to="/ai-creator"
                >
                <img src="/ai_creator/ai-creator-twinkle.svg"/>  {items[0]} 
                <span className="beta-tag">BETA</span>
                </NavLink>
              </li>
              <li className={`links nav-item m-auto text-settings`}>
                <NavLink
                  className="nav-link header-text font manrope-f f-16 w-700 black-2b"
                  style={{ lineHeight: "0" }}
                  activeClassName="active"
                  to="/pricing"
                >
                  {items[1]}
                </NavLink>
              </li>
              <li className={`links nav-item m-auto text-settings`}>
              <NavLink
                  className="nav-link header-text font manrope-f f-16 w-700 black-2b"
                  style={{ lineHeight: "0" }}
                  activeClassName="active"
                  to="/blog"
                >
                  {items[2]}
                </NavLink>
              </li>
              <li className={`links nav-item m-auto text-settings`}>
                <NavLink
                  className="nav-link header-text font manrope-f f-16 w-700 black-2b"
                  style={{ lineHeight: "0" }}
                  activeClassName="active"
                  to="/contact-us"
                >
                  {items[3]}
                </NavLink>
              </li>
              <li className={`links nav-item m-auto text-settings`}>
              <NavLink
                  className="nav-link header-text font manrope-f f-16 w-700 black-2b"
                  style={{ lineHeight: "0" }}
                  activeClassName="active"
                  to="/about-us"
                >
                  {items[4]}
                </NavLink>
              </li>
              <li
                className="nav-item m-auto  display-button-large"
                style={{ cursor: "pointer" }}
              >
                <NavLink
                  className="nav-link"
                  to={{ pathname: "https://app.all3d.ai/" }}
                  target="_blank"
                >
                  <button
                    id="getStart"
                    className={`btn login-btn font manrope-f f-16 w-700`}
                    disabled={false}
                  >
                    {items[5]}
                  </button>
                </NavLink>
              </li>
              <li
                className="nav-item m-auto  display-button-large"
                style={{ cursor: "pointer" }}
              >
                <NavLink
                  className="nav-link"
                  to={{ pathname: "https://app.all3d.ai/register" }}
                  target="_blank"
                >
                  <button
                    id="getStart"
                    className={`btn login-btn-blk font manrope-f f-16 w-700`}
                    disabled={false}
                  >
                    {items[6]}
                  </button>
                </NavLink>
              </li>
              <li
                className={`links nav-item m-auto text-settings  display-button-small`}
                style={{ borderBottom: "none" }}
              >
                <button
                  onClick={() => window.location.href = "https://app.all3d.ai/"}
                  id="getStart"
                  className={`btn login-btn font manrope-f f-16 w-700 mr-8`}
                  disabled={false}
                >
                  {items[5]}
                </button>
                <button
                  onClick={() => window.location.href = "https://app.all3d.ai/register"}
                  id="getStart"
                  className={`btn login-btn-blk font manrope-f f-16 w-700`}
                  disabled={false}
                >
                  {items[6]}
                </button>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    );
  }
}

export default NavBar;
